import React, { memo, useState } from 'react'
import { View, Text, StyleSheet } from 'react-native'
import { useTheme } from '../feed/ThemeContext'
import { getShortName } from '@there/components/utils/get-short-name'
import { useHover } from '@there/components/shared/use-hover'
import { useNudge } from '../feed/use-nudge'
import { minifyUserForProfilePhoto, ProfilePhoto } from '../feed/ProfilePhoto'
import { useRtcContext } from '@there/components/shared/use-rtc'
import { AvatarInfo, PresenceInfo, UserInfo } from '@there/sun/utils/node-types'
import isEqual from 'react-fast-compare'
import { useUser } from '@there/components/atoms/userAtom'
import { NudgeButton } from '@there/components/person/NudgeButton'
import { AvatarIndicator } from '@there/components/person/Indicators'
import { useUiContext } from '../shared/UiContext'
import { usePresencesContext } from '../shared/PresenceManager'
import { useUserStatus } from '../utils/get-user-status'
import { TimezoneTime } from '@there/components/main/TimezoneTime'
import { Tooltip } from '@there/components/shared/Tooltip'
import useInterval from '@use-it/interval'
import { useAtom } from 'jotai'
import { featurePreviewsAtom, feedOpenAtom } from '../atoms/feedAtoms'
import { MemberDropdown } from '../dropdown/MemberDropdown'
import { useGlobalTypingContext } from '@there/components/shared/GlobalTypingManager'
import { TypingIcon } from '@there/components/feed/PresenceClueIcons'
import { useSpring, a } from '@react-spring/native'
import { Pressable } from '@there/components/shared/Pressable'
import { activeNewChatPeerAtom } from '@there/components/atoms/chatAtom'
import { contentViewAtom } from '@there/components/atoms/contentViewAtom'
import { useMainWindowContext } from '../shared/use-main-window'

export const rowHeight = 42
export const profileWidth = 24

export const MemberRow = memo(
  ({
    user: givenUser,
    avatar,
    userId,
    isUs,
    presence,
    currentUserTimezone,
    isAdmin,
    memberId,
    onAvatarPress,
    index,
  }: {
    avatar: AvatarInfo | undefined
    userId: string
    user?: UserInfo | undefined
    isUs?: boolean
    borderBottom?: boolean /** override text under name */
    subText?: string /** still fetching, don't show online status */
    showPresence?: boolean
    currentUserTimezone?: string | null
    avatarId?: string
    inviteAccepted?: boolean | null
    presence?: PresenceInfo
    isAdmin: boolean
    memberId: string
    onAvatarPress?: () => void
    index: number
  }) => {
    const t = useTheme()
    const [hovered, hoverListeners] = useHover()
    const [avatarHovered, avatarHoverListeners] = useHover()
    let [isFeedOpen] = useAtom(feedOpenAtom)

    let [featurePreviews] = useAtom(featurePreviewsAtom)
    let isV2 = featurePreviews.includes('v2')

    const [, sendNudge] = useNudge({ recipientId: userId })
    const { walkieState } = useRtcContext()
    let { hasCustomBackground } = useUiContext()
    let {
      state: { userIdsTyping },
    } = useGlobalTypingContext()
    let user = useUser(userId)
    let isOnline = user?.online
    const participant = walkieState.participants?.[userId]

    let stableUser = givenUser || user

    let isWorking = presence?.working

    let isTyping = userIdsTyping[userId]
    let typingIconStyles = useSpring({
      opacity: isTyping && isOnline ? 1 : 0,
      config: {
        tension: 400,
        mass: 0.1,
      },
    })

    // update every 60s when feedIsOpen
    let [refreshKey, setState] = useState(false)
    useInterval(
      () => {
        setState((s) => !s)
      },
      isFeedOpen ? 60_000 : null,
    )

    let [shortStatus, userStatus] = useUserStatus({
      presence,
      isOnline: Boolean(isOnline),
      lastOnline: stableUser?.lastOnline,
      status: stableUser?.status,
      refreshKey,
    })

    let [, setActiveNewChatId] = useAtom(activeNewChatPeerAtom)
    let { dispatch } = useMainWindowContext()

    function openChat() {
      if (!isV2) return
      setActiveNewChatId({
        peerUserId: stableUser?.id,
      })
      dispatch({ type: 'change mode', mode: 'chat' })
    }

    if (!stableUser) return null
    let shortName = getShortName(stableUser) || ''

    let idleState = isUs ? walkieState?.idleState : participant?.idleState

    return (
      <MemberDropdown isAdmin={isAdmin} memberId={memberId}>
        <Pressable
          onPress={openChat}
          disabled={!isV2}
          style={({ hovered, pressed }) => [
            styles.rowWrapper,
            {
              // paddingLeft: t.spaces.sidePaddingNarrow,
              // paddingRight: t.spaces.sidePaddingNarrow - 4,
              // ---
              // borderColor: t.colors.separatorLine,
              // borderBottomWidth: 1,
              borderRadius: 8,
              paddingHorizontal: 8,
              marginLeft: -8,
              marginRight: -8,
            },
            // index === 0 && { paddingTop: 0 },
            hovered && { backgroundColor: `rgba(255,255,255,0.11)` },
            pressed && { backgroundColor: `rgba(255,255,255,0.08)` },
          ]}
          {...hoverListeners}
        >
          <View style={[styles.avatarWrapper]} {...avatarHoverListeners}>
            {stableUser && (
              <ProfilePhoto
                user={minifyUserForProfilePhoto(stableUser)}
                size={profileWidth}
              />
            )}

            <AvatarIndicator
              right={1}
              bottom={1}
              type={
                idleState && idleState === 'idle'
                  ? 'idle'
                  : isWorking && isOnline
                  ? 'working'
                  : isOnline
                  ? 'online'
                  : 'offline'
              }
            />
          </View>

          {/* Content */}
          <View
            style={{
              flexGrow: 1,
              flexShrink: 1,
              position: 'relative',
              alignSelf: 'stretch',
              // align middle
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <View
              style={{
                flexDirection: 'row',
                width: '100%',
                alignItems: 'center',
                marginTop: 0,
              }}
            >
              {/* Name */}
              <Text
                numberOfLines={1}
                style={[
                  styles.name,
                  {
                    fontSize: t.fontSizes.normal,
                    color: t.colors['tertiaryText'],
                    flexShrink: 1,
                    marginBottom: 0,
                  },
                  hasCustomBackground && {
                    color: t.colors['secondaryText'],
                    textShadowOffset: { width: 0, height: 1 },
                    textShadowRadius: 2,
                    textShadowColor: 'rgba(0, 0, 0, 0.25)',
                  },
                ]}
              >
                {shortName}
              </Text>

              {shortStatus || isUs ? (
                <Text
                  numberOfLines={1}
                  style={[
                    {
                      fontSize: t.fontSizes.small,
                      color: t.colors['quaternaryText'],
                      marginLeft: 4,
                      textAlignVertical: 'center',
                    },
                    hasCustomBackground && {
                      color: t.colors['tertiaryText'],
                      textShadowOffset: { width: 0, height: 1 },
                      textShadowRadius: 2,
                      textShadowColor: 'rgba(0, 0, 0, 0.25)',
                    },
                  ]}
                >
                  {isUs ? `(You)` : shortStatus}
                </Text>
              ) : null}

              <View style={{ marginLeft: 'auto' }} />

              <Tooltip enabled={isTyping} label={`${shortName} is typing`}>
                <a.View
                  style={{
                    height: 7,
                    width: 9,
                    marginLeft: 2,
                    marginRight: 2,
                    ...typingIconStyles,
                  }}
                >
                  <TypingIcon
                    color={t.colors.tertiaryText}
                    style={{
                      boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.35)',
                    }}
                  />
                </a.View>
              </Tooltip>

              {/* timezone */}
              {stableUser.timezone &&
              // If shortname is long, show on hover
              (shortName.length < 7 || hovered) &&
              // Only show if current user timezone is different
              stableUser.timezone !== currentUserTimezone ? (
                <Tooltip
                  label={`${stableUser.timezone
                    .split('/')[1]
                    .replace('_', ' ')} Time`}
                >
                  <View style={{ height: 12 }}>
                    <Text
                      style={{
                        fontVariant: ['tabular-nums'],
                        color: t.colors.quaternaryText,
                        fontSize: t.fontSizes.small,
                        flexShrink: 0,
                        lineHeight: 12,
                        height: 12,
                        textAlignVertical: 'center',
                      }}
                      numberOfLines={1}
                    >
                      <TimezoneTime timeZone={stableUser.timezone} />
                    </Text>
                  </View>
                </Tooltip>
              ) : null}
            </View>

            {/* Status */}
            <Text
              numberOfLines={2}
              style={[
                styles.statusText,
                {
                  fontSize: t.fontSizes.small,
                  color: t.colors['quaternaryText'],
                  // letterSpacing: userStatus.length > 20 ? -0.3 : 0,
                  letterSpacing: 0,
                },
                hasCustomBackground && {
                  color: t.colors['tertiaryText'],
                  textShadowOffset: { width: 0, height: 1 },
                  textShadowRadius: 2,
                  textShadowColor: 'rgba(0, 0, 0, 0.25)',
                },
              ]}
            >
              {userStatus}
            </Text>

            {/** actions */}
            <View style={styles.nudgeWrapper}>
              <NudgeButton show={hovered} sendNudge={sendNudge} isUs={isUs} />
            </View>
          </View>
        </Pressable>
      </MemberDropdown>
    )
  },
  isEqual,
)

export const styles = StyleSheet.create({
  rowWrapper: {
    width: 'auto',
    // height: rowHeight,
    // minHeight: rowHeight,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingVertical: 6,
  },
  avatarWrapper: {
    position: 'relative',
    height: profileWidth + 2,
    width: profileWidth + 2,
    marginRight: 6,
  },

  name: {
    lineHeight: 15,
    fontWeight: 'normal',
    textAlignVertical: 'center',
  },
  statusText: {},

  nudgeWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 'auto',
    flexGrow: 1,
    justifyContent: 'flex-end',
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: 2,
  },
})
