/* eslint-disable react-hooks/exhaustive-deps */
import { Severity } from '@sentry/browser'
import Sentry from '@there/app/utils/sentry'
import { EmojiView } from '@there/components/main/EmojiView'
import {
  GotSignalData,
  useGotSignal,
} from '@there/components/main/useGotSignal'
import { useAlert } from '@there/components/modal/use-alert'
import { ContentHint } from '@there/components/rtc/ContentHintPicker'
import { Quality } from '@there/components/rtc/QualityPicker'
import { useScreenShareKeyboardEvents } from '@there/components/screen-share/useScreenShareEvents'
import { useAppContext } from '@there/components/shared/AppContext'
import {
  useCurrentMediaDevicesContext,
  useMediaDevicesState,
} from '@there/components/shared/CurrentMediaDevicesContext'
import { IceServersState } from '@there/components/shared/use-ice-servers'
import { useLatest } from '@there/components/shared/use-latest'
import {
  initialWalkieState,
  PeerOnJsonData,
  SendToAll,
  SendToUser,
  TriggerParticipantsReset,
  useRtcPeers,
  WalkiePeersProps,
  WalkieState,
} from '@there/components/shared/use-rtc-peers'
import { UniversalDataChannel } from '@there/components/shared/use-rtc-universal-data'
import { StreamsObject, useStream } from '@there/components/shared/use-streams'
import { getRegion } from '@there/components/sun/context'
import { useNurMutation } from '@there/components/sun/use-mutation'
import { SubscriptionResult } from '@there/components/sun/utils/types'
import { PartialUser } from '@there/components/urql/fragments/userInfo'
import {
  ipc,
  isElectron,
  RtcDataChannelDataOverIpc,
  useListenToIpc,
} from '@there/desktop/utils/electron-api'
import { DialogInfo } from '@there/sun/utils/node-types'
import { fromGlobalId } from '@there/tower/utils/global-id'
import { RtcAvatar } from 'components/shared/use-rtc-avatars'
import { atom, useAtom } from 'jotai'
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { feedModeAtom } from '../atoms/feedAtoms'
import { useToast } from '../modal/use-toast'
import {
  initialState as ScreenShareInviteInitialState,
  State as ScreenShareInviteState,
  useScreenShareInviteManager,
} from './use-screenshare-invite-manager'
const debug = require('debug')('desktop:use-rtc')

export type RtcManager = {
  sendToUser: SendToUser
  sendToUserScreenChannel: SendToUser
  sendToAll: SendToAll
  walkieState: WalkieState
  userIdsSeeOurScreen: string[]
  participants: WalkieCallParticipant[]
  ourObservers: string[]
  peerMicrophoneReady: boolean
  screenShareInviteState: ScreenShareInviteState
  talk: () => void
  talkOver: () => void
  toggleTalk: () => void
  toggleCamera: () => void
  stopCamera: () => void
  toggleScreenSharing: () => void
  toggleSystemAudioSharingState: () => void
  isParticipant: (userId: string) => boolean
  systemMuteChanged: (muted: boolean) => void
  volumeMuteChanged: (muted: boolean) => void
  inviteUserToScreen: ({
    callId,
    userId,
  }: {
    callId: string
    userId: string
  }) => void
  prepareToObserve: ({
    callId,
    hostUserId,
  }: {
    callId: string
    hostUserId: string
  }) => void
  stopSharingScreen: () => void
  updateScreenSettings: (input: {
    quality?: Quality
    contentHint?: ContentHint
  }) => void
  getUserIdsViewingOurScreen: () => string[]
  addJsonDataEventListener: (handlerFunction: PeerOnJsonData) => void
  removeJsonDataEventListener: (handlerFunction: PeerOnJsonData) => void
  triggerConnectionRecheck: () => void
  triggerConnectionReset: () => void
  triggerParticipantsReset: TriggerParticipantsReset
  triggerParticipantReset: (userId: string) => void
  startScreenSharing: () => void
  stopScreenSharing: () => void
  joinParticipantScreen: (userId: string) => void
  leaveParticipantScreen: (userId?: string) => void
  toggleRemoteControlPrivilege: () => void
  muteUser: (userId: string) => void
  _streams: StreamsObject | undefined
  enabled: boolean
  isInCall: boolean
  isInitiallyConnecting: boolean
  inActiveCall: boolean
  universalDataChannel: UniversalDataChannel
}
export type RoomManager = RtcManager

type CallParticipant = RtcAvatar
export type WalkieCallParticipant = CallParticipant

type Props = Partial<WalkiePeersProps> & {
  enabled: boolean
  onCallJoin?: (input: { spaceId: string; participants: PartialUser[] }) => void
  canAcceptACall?: () => boolean
  participants: RtcAvatar[]
  dialogId: string | undefined
  sessionId: string | undefined | null
  participantUserIds: string[]
  iceServersState: IceServersState
  dialog: DialogInfo | undefined
}

export type SocketEventContext = { callId: string; userId?: string }

const middleEastSpaceIds = new Set([
  'ckrjpdn2638152eyfjsgmghd55',
  'ckrqakwae54828eyjlh4yb11t0',
  'ckkyg8tdc5136esfliyjzq9e1',
  'ckr7f9jgq79550eyiaxgcx37vi',
  'ckoypgqwu4683eygypgnf2grz',
  'ckr7f9jgq79550eyiaxgcx37vi',
  'cksbiql0d22773eyi0y04esyhf',
])

export function useRtc({
  enabled,
  onJsonData,
  onSomeoneTalk,
  onSomeoneTalkStop,
  participants,
  sessionId,
  dialogId,
  participantUserIds,
  iceServersState,
  dialog,
}: Props): RoomManager {
  const { currentUserId, activeSpaceId } = useAppContext()
  let nodeId = dialogId

  const inRoom = !!dialogId
  const inActiveCall = useMemo(
    () =>
      Boolean(dialogId && participants.some((p) => p.userId !== currentUserId)),
    [currentUserId, dialogId, participants],
  )
  let silent = !!dialog?.silent

  const [{ currentMic, currentCamera }] = useMediaDevicesState()
  const [
    screenShareInviteState,
    screenShareInviteDispatch,
  ] = useScreenShareInviteManager()

  const [requestStream, streams] = useStream({ moduleEnabled: enabled })

  //Event emitter
  let currentJsonDataListeners = useRef<Set<PeerOnJsonData>>(new Set())

  let addJsonDataEventListener = useCallback(
    (handlerFunction: PeerOnJsonData) => {
      currentJsonDataListeners.current.add(handlerFunction)
    },
    [],
  )
  let removeJsonDataEventListener = useCallback((handlerFunction) => {
    currentJsonDataListeners.current.delete(handlerFunction)
  }, [])

  let jsonDataEventEmitter = useCallback((context, data, remotePrivilege) => {
    for (const handler of currentJsonDataListeners.current) {
      handler(context, data, remotePrivilege)
    }
  }, [])

  let [, sendSignalMutation] = useNurMutation({
    method: 'sendSignal',
  })

  const sendSignal = useCallback(
    (signalData: {
      dialogId: string
      receiverId: string
      signal: string
      peerId: string
    }) => {
      if (isElectron) {
        ipc?.invoke('rtc:broadcast-signal', { signalData })
      } else {
        sendSignalMutation({ ...signalData })
      }
    },
    [sendSignalMutation],
  )

  let onJsonDataForKeyboardEvents = useScreenShareKeyboardEvents({
    currentUserId,
  })

  let participantUserIdsRef = useLatest(participantUserIds)

  const useMiddleEastTurn = useMemo(
    () => middleEastSpaceIds.has(fromGlobalId(activeSpaceId || '').id),
    [activeSpaceId],
  )

  let [region, setRegion] = useState('global')
  useEffect(() => {
    getRegion().then((region) => {
      setRegion(region)
    })
  }, [])

  useEffect(() => {
    if (!useMiddleEastTurn) return
    debug('middle east TURN in list.')
  }, [useMiddleEastTurn])

  const {
    sendToAll,
    sendToUser,
    sendToUserScreenChannel,
    walkieState,
    onSignal,
    dispatch,
    peerMicrophoneReady,
    triggerConnectionRecheck,
    triggerParticipantsReset,
    triggerParticipantReset,
    ourObservers,
    universalDataChannel,
  } = useRtcPeers({
    enabled:
      // silent shouldn't be in a call
      !silent && Boolean(dialogId) && enabled,
    streams,
    iceServers: [
      {
        urls: ['stun:stun.usenoor.com', 'stun:stun.l.google.com:19302'],
      },

      // Germany
      {
        urls: ['turn:168.119.252.117:3478'],
        username: 'user',
        credential: 'pass',
      },
    ],
    callToken: 'fakeToken',
    callId: dialogId,
    sessionId,
    participants,
    silent: dialog?.silent,
    shouldUserHearUs: useCallback((inputUserId: string) => true, []),
    onJsonData: useCallback(
      (context, data, remotePrivilege) => {
        onJsonData && onJsonData(context, data, remotePrivilege)
        jsonDataEventEmitter(context, data, remotePrivilege)
        onJsonDataForKeyboardEvents(context, data, remotePrivilege)

        // comment for now
        ipc
          ?.invoke('rtc-data-channel-data', {
            userId: context.userId,
            data,
          } as RtcDataChannelDataOverIpc)
          .catch(() => {})
      },
      [jsonDataEventEmitter, onJsonData, onJsonDataForKeyboardEvents],
    ),

    onSomeoneTalk,
    onSomeoneTalkStop,

    broadcastSignal: useCallback(
      (recipientId, { signal, peerId }) => {
        if (!nodeId) {
          console.error('No parent id')
          Sentry.captureMessage('No parent id when broadcasting signal', {
            level: Severity.Error,
            extra: { recipientId },
          })
          return
        }

        if (!activeSpaceId) {
          console.error('No space id')
          Sentry.captureMessage('No space id when broadcasting signal', {
            level: Severity.Error,
            extra: { recipientId, nodeId },
          })
          return
        }

        let signalJson = ''
        signalJson =
          typeof signal !== 'string' ? JSON.stringify(signal) : signal

        sendSignal({
          dialogId: nodeId,
          receiverId: recipientId,
          signal: signalJson,
          peerId: peerId,
        })
      },
      [activeSpaceId, nodeId, sendSignal],
    ),
  })

  let participantsRef = useLatest(participants)

  let lastSignals = useRef<any[]>([])

  const rtcGotSignal = useCallback(
    ({ data }: SubscriptionResult<GotSignalData>) => {
      if (!data) {
        debug('empty signal was received')
        return
      }

      // ----
      for (let lastSignal of lastSignals.current) {
        if (
          lastSignal.senderId === data.senderId &&
          lastSignal.signal === data.signal &&
          lastSignal.dialogId === data.dialogId
        ) {
          console.info('duplicate signal was received')
          return
        }
      }
      lastSignals.current.push(data)
      // keep it low
      if (lastSignals.current.length > 20) {
        lastSignals.current.shift()
      }
      // ----

      let signal = data.signal
      let senderId = data.senderId
      let peerId = data.peerId

      let signalerInRoom = participantsRef.current?.find(
        (participant) => participant.userId === senderId,
      )

      if (!signalerInRoom) {
        debug(`Signaller is not in the current room.`)
        return
      }

      debug('incoming signal', signal, senderId)
      onSignal(senderId, signal, { peerId })
    },
    [onSignal, participantsRef],
  )

  const gotSignal = useCallback(
    (signalData: SubscriptionResult<GotSignalData>) => {
      if (!signalData) {
        debug('empty signal was received')
        return
      }
      if (isElectron) {
        ipc?.invoke('rtc:got-signal', { signalData, enabled: isElectron })
      } else {
        rtcGotSignal(signalData)
      }
    },
    [rtcGotSignal],
  )

  // when isElectron and in rtc window
  useListenToIpc(
    'rtc:got-signal',
    useCallback(
      (event, signalData) => {
        if (!enabled) {
          debug('ignored signal when not enabled')
          return
        }

        if (!signalData) {
          debug('[rtc:got-signal] signalData is unAvailable')
          return
        }
        rtcGotSignal(signalData)
      },
      [enabled, rtcGotSignal],
    ),
  )

  useGotSignal({ onData: gotSignal, callbackOnly: true })

  let requestStreamFunction = useLatest(requestStream)
  let stopAllFunction = useLatest(streams.stopAll)

  let micStopperTimeoutRef = useRef(0)
  let micStopperTimeoutRef2 = useRef(0)
  let micStreamStopper = useLatest({
    cancel() {
      if (micStopperTimeoutRef.current) {
        clearTimeout(micStopperTimeoutRef.current)
      }
      if (micStopperTimeoutRef2.current) {
        clearTimeout(micStopperTimeoutRef2.current)
      }
    },
    startStopper() {
      if (micStopperTimeoutRef.current) {
        clearTimeout(micStopperTimeoutRef.current)
      }
      micStopperTimeoutRef.current = setTimeout(() => {
        debug('Stopped mic stream in timeout')
        streams.mic.stop()
      }, 2000)
      // micStopperTimeoutRef2.current = setTimeout(() => {
      //   debug('Toggled mic off in timeout')
      //   talkOver()
      //   // After 80s idle
      // }, 80_000)
    },
  })

  useEffect(() => {
    if (!enabled) return
    if (!requestStreamFunction.current) return

    // To disable getting mic stream when alone (used to be `inRoom`)
    if (inActiveCall && !silent) {
      // if ((inActiveCall || (inRoom && walkieState.weTalking)) && !silent) {
      requestStreamFunction.current(['mic'], { enabled: false }).then(() => {
        debug('got mic stream')
      })
      micStreamStopper.current?.cancel()
      // } else if (!inRoom) {
    } else {
      // micStreamStopper.current?.startStopper()
      // to fix issue with mic
      // streams.mic.stop()
      // Add a buffer to prevent useEffect potential bugs
      // micStreamStopper.current?.startStopper()
    }

    return () => {
      // streams.mic.stop()
      micStreamStopper.current?.startStopper()
    }
  }, [
    enabled,
    //commented to fix m-line error
    // inRoom,
    // walkieState.weTalking,
    inActiveCall,
    silent,
    // nodeId,
    requestStreamFunction,
    micStreamStopper,
  ])

  // DISABLED SWITCH MIC
  let switchDeviceRef = useLatest(streams.mic.switchDevice)
  let switchCameraDeviceRef = useLatest(streams.camera.switchDevice)
  let currentCameraId = currentCamera?.id

  // Sync Microphone Changes
  useEffect(() => {
    if (!enabled) return
    if (!inActiveCall) return
    if (!currentMic) return
    if (!switchDeviceRef || !switchDeviceRef.current) return

    debug('Switching microphone device')
    switchDeviceRef.current(currentMic.id)
  }, [currentMic, enabled, inActiveCall, switchDeviceRef])

  let micStream = streams.mic.stream
  let weTalking = walkieState.weTalking
  let { openModal: openMicWarningModal } = useToast({
    title: 'Please check your mic',
    description: 'It seems your current mic does not have levels.',
    dismissLabel: 'Dismiss',
  })
  useEffect(() => {
    if (!enabled) return
    if (!micStream) return
    if (!weTalking) return
    let audioCtx = new AudioContext()
    let analyser = audioCtx.createAnalyser()
    let source = audioCtx.createMediaStreamSource(micStream)
    source.connect(analyser)

    analyser.fftSize = 2048
    let bufferLength = analyser.frequencyBinCount
    let dataArray = new Uint8Array(bufferLength)

    let interval = setInterval(() => {
      analyser.getByteTimeDomainData(dataArray)
      let soundCheck = dataArray.findIndex((val) => val !== 128)
      if (soundCheck === -1) {
        if (isElectron) {
          ipc?.invoke('rtc:mic-alert')
        } else {
          openMicWarningModal()
        }
        clearInterval(interval)
      }
    }, 1000)
    return () => clearInterval(interval)
  }, [micStream, weTalking])

  useListenToIpc(
    'rtc:mic-alert',
    useCallback((event, data) => {
      openMicWarningModal()
    }, []),
  )

  // Sync Microphone Changes
  useEffect(() => {
    if (!enabled) return
    if (!dialogId) return
    if (!currentCameraId) return
    if (!switchCameraDeviceRef?.current) return
    if (!walkieState.weSharingCamera) return

    debug('Switching camera device')
    switchCameraDeviceRef.current(currentCameraId)
  }, [currentCameraId, enabled, switchCameraDeviceRef])

  let { openModal: openSilentModal } = useAlert({
    alertText: 'You are in a silent room',
    alertDescription:
      'Enabling microphone or camera is not allowed in silent rooms. Go to a different room or change room settings.',
    alertIcon: <EmojiView size={34} children="🤫" />,
    submitLabel: 'OK',
  })

  // Not using ref for this results in using stale value
  let silentRef = useLatest(silent)

  // Walkie
  // Could take recipient and confirm only if we're in a call with them
  const talk = useCallback(() => {
    if (silentRef.current) {
      openSilentModal()
      return
    }

    if (inActiveCall) {
      triggerConnectionRecheck()
    } else if (!inActiveCall) {
      console.warn('Talk was called, when not actively in call.')
    }

    debug('talk')
    dispatch({ type: 'talking', talking: true })

    // Change muted in useEffect above
  }, [inActiveCall, triggerConnectionRecheck, dispatch, silentRef])

  // Could take recipient and confirm only if we're in a call with them
  const talkOver = useCallback(() => {
    if (inActiveCall) {
      triggerConnectionRecheck()
    } else if (!inActiveCall) {
      console.warn('Talk was called, when not actively in call.')
    }

    dispatch({ type: 'talking', talking: false })
  }, [dispatch, inActiveCall, triggerConnectionRecheck])

  const toggleTalk = useCallback(() => {
    if (walkieState.weTalking) {
      talkOver()
    } else {
      talk()
    }
  }, [walkieState.weTalking, talkOver, talk])

  // disable MIC when our mic is open and we have no callId
  useEffect(() => {
    if (!enabled) return
    if (!walkieState.weTalking) return
    if (!nodeId) {
      toggleTalk()
    }
  }, [enabled, nodeId, toggleTalk, walkieState.weTalking])

  // const inviteUserToScreen = useCallback(
  //   ({ callId, userId }: { callId: string; userId: string }) => {
  //     screenShareInviteDispatch({ type: 'invite added', userId, callId })
  //   },
  //   [screenShareInviteDispatch],
  // )

  // const prepareToObserve = useCallback(
  //   ({ callId, hostUserId }: { callId: string; hostUserId: string }) => {
  //     screenShareInviteDispatch({ type: 'invite accepted', hostUserId, callId })
  //   },
  //   [screenShareInviteDispatch],
  // )

  // const stopSharingScreen = useCallback(() => {
  //   if (!walkieState.weSharingScreen) return
  //   screenShareInviteDispatch({ type: 'call ended' })
  //   dispatch({
  //     type: 'sharing screen ended',
  //   })
  // }, [dispatch, screenShareInviteDispatch, walkieState.weSharingScreen])
  let { preferredSource, systemAudioDevice } = useCurrentMediaDevicesContext()
  let sourceIdRef = useLatest(preferredSource?.id)

  // get screen stream when weSharingScreen State changed
  useEffect(() => {
    if (!enabled) return
    if (!requestStreamFunction.current) return
    if (!stopAllFunction.current) return

    if (walkieState.weSharingScreen) {
      requestStreamFunction.current(['screen'], { enabled: true }).then(() => {
        debug('got screen stream')

        // Started
        ipc
          ?.invoke('rtc:sharing-changed', {
            sharing: true,
            sourceId: sourceIdRef.current,
          })
          .catch(() => {})
      })
    } else {
      // Stop
      ipc
        ?.invoke('rtc:sharing-changed', {
          sharing: false,
        })
        .catch(() => {})
    }
  }, [
    enabled,
    requestStreamFunction,
    stopAllFunction,
    walkieState.weSharingScreen,
  ])

  useEffect(() => {
    if (!enabled) return

    if (!walkieState.weSharingScreen && streams.screen.stream) {
      console.info('Stopped screen stream bc sharing stopped')
      streams.screen.stop()
    }
  }, [dispatch, walkieState.weSharingScreen, streams.screen, enabled])

  let stopCameraFunc = useLatest(streams.camera.stop)

  // get camera stream when weSharingCamera state changed
  useEffect(() => {
    if (!enabled) return
    if (!requestStreamFunction.current) return

    if (walkieState.weSharingCamera) {
      requestStreamFunction.current(['camera'], { enabled: true }).then(() => {
        debug('got camera stream')
      })
    } else {
      stopCameraFunc.current?.()
    }

    return () => {
      stopCameraFunc.current?.()
    }
  }, [
    // DO NOT TOUCH THESE DEPS OR IT WILL BREAK
    enabled,
    stopCameraFunc,
    requestStreamFunction,
    walkieState.weSharingCamera,
  ])

  useEffect(() => {
    if (!enabled) return

    ipc?.invoke('rtc:call-status-changed', { inActiveCall }).catch(() => {})
  }, [inActiveCall])

  const systemMuteChanged = useCallback((muted: boolean) => {
    dispatch({ type: 'system muted changed', muted })
  }, [])

  const volumeMuteChanged = useCallback((muted: boolean) => {
    dispatch({ type: 'volume muted changed', muted })
  }, [])

  const isParticipant = useCallback(
    (userId: string) => {
      const participant = walkieState.participants[userId]

      return !!participant
    },
    [walkieState.participants],
  )

  const getUserIdsViewingOurScreen = useCallback(() => {
    return ourObservers
  }, [ourObservers])

  // close sharing screen if no one seeing us
  let userIdsSeeOurScreen = getUserIdsViewingOurScreen()

  // Camera
  const stopCamera = useCallback(() => {
    dispatch({ type: 'sharing camera stopped' })
  }, [dispatch])

  // Camera
  const toggleCamera = useCallback(() => {
    if (walkieState.weSharingCamera) {
      dispatch({ type: 'sharing camera stopped' })
    } else {
      if (silent) {
        openSilentModal()
        return
      }

      // make weSharingCamera state true
      dispatch({ type: 'sharing camera started' })
    }
  }, [
    dispatch,
    walkieState.weSharingCamera,
    silent,
    walkieState.weSharingScreen,
  ])

  const triggerConnectionReset = useCallback(() => {
    // Debug only
    for (const userId of participantUserIds) dispatch({ type: 'left', userId })
  }, [participantUserIds, dispatch])

  const startScreenSharing = useCallback(() => {
    if (!currentUserId) return
    if (!inActiveCall) {
      console.warn('Start screen share was called, when not actively in call.')
    }
    if (silent) {
      openSilentModal()
      return
    }

    debug('start screen share')
    dispatch({ type: 'sharing screen started', currentUserId })
  }, [inActiveCall, dispatch, silent, currentUserId])

  const stopScreenSharing = useCallback(() => {
    if (!inActiveCall) {
      console.warn('End screen share was called, when not actively in call.')
    }
    debug('end screen share')
    dispatch({ type: 'sharing screen ended' })
  }, [inActiveCall, dispatch])

  const toggleScreenSharing = useCallback(() => {
    if (walkieState.weSharingScreen) {
      stopScreenSharing()
    } else {
      startScreenSharing()
    }
  }, [walkieState.weSharingScreen, stopScreenSharing, startScreenSharing])

  const toggleRemoteControlPrivilege = useCallback(() => {
    if (walkieState.remoteControlPrivilege) {
      dispatch({
        type: 'remote control privilege changed',
        remoteControlPrivilege: false,
      })
    } else {
      dispatch({
        type: 'remote control privilege changed',
        remoteControlPrivilege: true,
      })
    }
  }, [walkieState.remoteControlPrivilege])

  // stop sharing screen when ourObservers left
  let ourObserverCountRef = useRef(0)
  let ourObserversLength = ourObservers.length
  useEffect(() => {
    if (!enabled) return
    if (!walkieState.weSharingScreen) {
      ourObserverCountRef.current = 0
      return
    }

    let stopSharingTimeout: number

    // our observers Left
    if (ourObserversLength === 0 && ourObserverCountRef.current > 0) {
      // stop sharing screen after 4min when observers left
      stopSharingTimeout = setTimeout(() => {
        if (ourObserversLength > 0) return
        if (ourObserverCountRef.current > 0) return
        stopScreenSharing()
      }, 240_000)
    }

    ourObserverCountRef.current = ourObserversLength
    return () => {
      if (stopSharingTimeout) {
        clearTimeout(stopSharingTimeout)
      }
    }
  }, [ourObserversLength, stopScreenSharing])

  const joinParticipantScreen = useCallback(
    (userId: string) => {
      dispatch({ type: 'joined participant screen', userId })
    },
    [stopCamera],
  )

  const leaveParticipantScreen = useCallback((userId?: string) => {
    // todo re-enable camera if we had disabled it
    dispatch({ type: 'left participant screen', userId })
  }, [])

  const updateScreenSettings = useCallback(
    (screenSettings: { contentHint?: ContentHint; quality?: Quality }) => {
      // todo re-enable camera if we had disabled it
      dispatch({ type: 'screen settings updated', ...screenSettings })
    },
    [],
  )

  // @note: Do not use this function directly -> we use it in useSystemAudio
  const toggleSystemAudioSharingState = useCallback(() => {
    if (walkieState.weSharingSystemAudio) {
      dispatch({ type: 'sharing system audio stopped' })
    } else {
      dispatch({ type: 'sharing system audio started' })
    }
  }, [walkieState.weSharingSystemAudio, dispatch])

  let systemAudioRef = useLatest(streams.systemAudio)
  // get system audio stream
  useEffect(() => {
    if (!enabled) return
    if (!systemAudioRef.current) return

    if (
      (walkieState.weSharingScreen || walkieState.weSharingSystemAudio) &&
      systemAudioDevice?.id
    ) {
      systemAudioRef.current.start(systemAudioDevice?.id)
    } else {
      systemAudioRef.current.stop()
    }
  }, [
    enabled,
    walkieState.weSharingScreen,
    walkieState.weSharingSystemAudio,
    systemAudioDevice,
    systemAudioRef,
  ])

  const muteUser = useCallback(
    (userId: string) => {
      sendToUser(userId, {
        type: 'user muted',
      })
    },
    [sendToUser],
  )

  useListenToIpc(
    'rtc:user-muted',
    useCallback(
      (event, data) => {
        if (!data || !data.userId) return
        muteUser(data.userId)
      },
      [muteUser],
    ),
  )

  useListenToIpc(
    'rtc:broadcast-signal',
    useCallback(
      (event, signalData) => {
        if (!signalData) {
          debug('[rtc:broadcast-signal] signalData is unAvailable')
          return
        }
        sendSignalMutation(signalData)
      },
      [sendSignalMutation],
    ),
  )

  // // Apply screen share invites to rtcState
  // useEffect(() => {
  //   // Check if screenShare's call initiated
  //   if (!enabled) return
  //   if (!screenShareInviteState.callId) return
  //   if (!walkieState.callId) return
  //   if (walkieState.callId !== screenShareInviteState.callId) return
  //   if (!walkieState.firstParticipantConnected) return

  //   // Set sharing screen state, if it is not
  //   if (!walkieState.weSharingScreen && screenShareInviteState.weSharing) {
  //     dispatch({
  //       type: 'sharing screen started',
  //     })
  //     talk()
  //   }

  //   // Check if invitee is our participant, add it to our observers
  //   for (let inviteeUserId of screenShareInviteState.ourObservers) {
  //     if (walkieState.participants[inviteeUserId]) {
  //       // dispatch({ type: 'noo', userId: inviteeUserId })
  //       screenShareInviteDispatch({
  //         type: 'invite cleared',
  //         userId: inviteeUserId,
  //       })
  //     }
  //   }
  // }, [
  //   currentUserId,
  //   dispatch,
  //   enabled,
  //   screenShareInviteDispatch,
  //   screenShareInviteState.callId,
  //   screenShareInviteState.ourObservers,
  //   screenShareInviteState.weSharing,
  //   talk,
  //   walkieState.callId,
  //   walkieState.firstParticipantConnected,
  //   walkieState.participants,
  //   walkieState.weSharingScreen,
  // ])

  // apply invited screen's state to observe
  // useEffect(() => {
  //   // Check if screenShare's call initiated
  //   if (!enabled) return
  //   if (!screenShareInviteState.callId) return
  //   if (!walkieState.callId) return
  //   if (walkieState.callId !== screenShareInviteState.callId) return
  //   if (!walkieState.firstParticipantConnected) return

  //   // we should not share screen in screen call, to add as observer
  //   if (screenShareInviteState.weSharing || walkieState.weSharingScreen) return

  //   // check hostUser Id available
  //   if (!screenShareInviteState.hostUserId) return

  //   // if hostUserId is our participant, apply observing states
  //   if (walkieState.participants[screenShareInviteState.hostUserId]) {
  //     setScreenHost({ userId: screenShareInviteState.hostUserId })
  //     setObservingState({ state: true })

  //     talk()

  //     screenShareInviteDispatch({ type: 'invite cleared' })
  //   }
  // }, [
  //   enabled,
  //   screenShareInviteDispatch,
  //   screenShareInviteState.callId,
  //   screenShareInviteState.hostUserId,
  //   screenShareInviteState.weSharing,
  //   setObservingState,
  //   setScreenHost,
  //   talk,
  //   walkieState.callId,
  //   walkieState.firstParticipantConnected,
  //   walkieState.participants,
  //   walkieState.weSharingScreen,
  // ])

  const isInitiallyConnecting =
    Boolean(walkieState.callId) &&
    walkieState.callId === dialogId &&
    !walkieState.firstParticipantConnected &&
    participants.length > 1

  const isInCall = Boolean(walkieState.callId)

  // Disable voice when room is silent
  useEffect(() => {
    if (silent) {
      talkOver()
      stopCamera()
      stopScreenSharing()
    }
  }, [silent])

  // Long connection time
  useEffect(() => {
    if (!enabled) return

    let timeout: number[] = []

    if (isInitiallyConnecting) {
      timeout.push(
        setTimeout(() => {
          Sentry.captureMessage(
            'Initial rtc connection took >4s',
            Severity.Debug,
          )
          console.info('>4s')
        }, 4000),
        setTimeout(() => {
          Sentry.captureMessage(
            'Initial rtc connection took >7s',
            Severity.Warning,
          )
          console.info('>7s')
        }, 7000),
        setTimeout(() => {
          Sentry.captureMessage(
            'Initial rtc connection took >14s',
            Severity.Error,
          )
          console.info('>14s')
        }, 14_000),
        setTimeout(() => {
          Sentry.captureMessage(
            'Initial rtc connection took >32s',
            Severity.Error,
          )
          console.info('>32s')
        }, 32_000),
      )
    }

    return () => {
      for (const id of timeout) clearTimeout(id)
      timeout = []
    }
  }, [enabled, isInitiallyConnecting])

  let [, setFeedMode] = useAtom(feedModeAtom)

  // manage feedMode in browser ('observer' | 'main)
  useEffect(() => {
    if (!enabled || isElectron) return
    if (walkieState.isObserving) {
      setFeedMode('Observer')
    } else {
      setFeedMode('Main')
    }
  }, [walkieState.isObserving, enabled])

  // Pass down atoms
  let [, setRtcCore] = useAtom(rtcCoreAtom)

  useEffect(() => {
    setRtcCore({
      isTalking: walkieState.weTalking,
      isInitiallyConnecting,
      isInCall,
      isSharingScreen: walkieState.weSharingScreen,
      isObserving: walkieState.isObserving,
      toggleTalk,
      toggleScreenSharing,
    })
  }, [
    toggleTalk,
    isInCall,
    isInitiallyConnecting,
    walkieState.weTalking,
    walkieState.weSharingScreen,
    walkieState.isObserving,
    setRtcCore,
    toggleScreenSharing,
  ])

  return {
    enabled,
    isInCall,
    inActiveCall,
    isInitiallyConnecting,
    sendToAll,
    sendToUser,
    sendToUserScreenChannel,
    systemMuteChanged,
    volumeMuteChanged,
    walkieState,
    userIdsSeeOurScreen,
    participants,
    ourObservers,
    peerMicrophoneReady,
    talk,
    talkOver,
    toggleTalk,
    stopCamera,
    toggleCamera,
    toggleScreenSharing,
    updateScreenSettings,
    universalDataChannel,
    isParticipant,
    screenShareInviteState,
    inviteUserToScreen: () => {
      console.warn('deprecated called.')
    },
    prepareToObserve: () => {
      console.warn('deprecated called.')
    },
    stopSharingScreen: () => {
      console.warn('deprecated called.')
    },
    getUserIdsViewingOurScreen,
    addJsonDataEventListener,
    removeJsonDataEventListener,
    triggerConnectionRecheck,
    triggerConnectionReset,
    triggerParticipantsReset,
    triggerParticipantReset,
    _streams: streams,
    // New screen share methods (v16, mo)
    startScreenSharing,
    stopScreenSharing,
    joinParticipantScreen,
    leaveParticipantScreen,
    muteUser,
    toggleRemoteControlPrivilege,
    toggleSystemAudioSharingState,
  }
}

const initialValue: RoomManager = {
  enabled: false,
  isInCall: false,
  inActiveCall: false,
  isInitiallyConnecting: false,
  sendToAll: () => 0,
  sendToUser: () => false,
  sendToUserScreenChannel: () => false,
  walkieState: initialWalkieState,
  userIdsSeeOurScreen: [],
  participants: [],
  ourObservers: [],
  peerMicrophoneReady: false,
  screenShareInviteState: ScreenShareInviteInitialState,
  talk: () => {},
  talkOver: () => {},
  triggerParticipantReset: (userId: string) => {},
  toggleTalk: () => {},
  toggleScreenSharing: () => {},
  stopCamera: () => {},
  toggleCamera: () => {},
  toggleSystemAudioSharingState: () => {},
  updateScreenSettings: () => {},
  isParticipant: (userId: string) => false,
  systemMuteChanged: (muted: boolean) => {},
  volumeMuteChanged: (muted: boolean) => {},
  inviteUserToScreen: ({
    callId,
    userId,
  }: {
    callId: string
    userId: string
  }) => {},
  prepareToObserve: ({
    callId,
    hostUserId,
  }: {
    callId: string
    hostUserId: string
  }) => {},
  universalDataChannel: {
    sendToUser: () => {
      return false
    },
    sendToAll: () => {
      return 0
    },
  },
  stopSharingScreen: () => {},
  getUserIdsViewingOurScreen: () => [],
  addJsonDataEventListener: (handlerFunction: PeerOnJsonData) => {},
  removeJsonDataEventListener: (handlerFunction: PeerOnJsonData) => {},
  triggerConnectionRecheck: () => {},
  triggerConnectionReset: () => {},
  triggerParticipantsReset: () => {},
  startScreenSharing: () => {},
  stopScreenSharing: () => {},
  joinParticipantScreen: () => {},
  leaveParticipantScreen: () => {},
  muteUser: (userId: string) => {},
  toggleRemoteControlPrivilege: () => {},
  _streams: undefined,
}

export const RtcContext = createContext<RoomManager>(initialValue)
export const useRtcContext = () => {
  return useContext(RtcContext)
}

// # Atoms
// Used for selective broadcasting of most widely used state properties
export const rtcCoreAtom = atom<{
  isTalking: boolean
  isInitiallyConnecting: boolean
  isInCall: boolean
  isSharingScreen: boolean
  isObserving: boolean
  toggleTalk: () => void
  toggleScreenSharing: () => void
}>({
  isTalking: false,
  isInitiallyConnecting: false,
  isInCall: false,
  isSharingScreen: false,
  isObserving: false,
  toggleTalk: () => {},
  toggleScreenSharing: () => {},
})
