import { Text, View, TouchableOpacity } from 'react-native'
import { memo, useEffect, useState } from 'react'
import { useHover } from '@there/components/shared/use-hover'
import { sidebarHorizontalPadding } from '../v2/Sidebar'
import { useTheme } from '../feed/ThemeContext'
import { NewChatWithPeer } from '../shared/SpaceChatsContext'
import { activeNewChatPeerAtom } from '../atoms/chatAtom'
import { contentViewAtom } from '../atoms/contentViewAtom'
import { getShortName } from '@there/shared/utilities/get-short-name'
import { useRerenderEvery } from '@there/components/v2/useRerenderEvery'
import {
  DocumentInfo,
  MemberWithUserInfo,
  UserInfo,
} from '@there/sun/utils/node-types'
import { WebStyled } from '../shared/WebStyled'
import { AvatarIndicator } from '../person/Indicators'
import { useNudge } from '../feed/use-nudge'
import { UnreadCount } from '@there/components/v2/UnreadCount'
import { Tooltip } from '@there/components/shared/Tooltip'
import { TimezoneTime } from '@there/components/main/TimezoneTime'
import { MuteIcon } from '../v2/MuteIcon'
import deepEqual from 'react-fast-compare'
import { useAtomValue, useUpdateAtom } from 'jotai/utils'
import { ChatMessageInfo, ChatPeer } from '../types/chat'
import { ChatAvatar } from '@there/components/shared/ChatAvatar'
import { useNurNode } from '@there/components/sun/use-node'
import { ChatPeerType } from '@there/components/v2/useRenderingPhase'
import { getMessageOneLiner } from '@there/components/message/helpers'
import { useDrop } from '../v2/useDrop'
import { useChatUploader } from '../v2/useFileUploadManager'
import { AvatarNudge } from '../person/AvatarNudge'
import { useDraftMessage, useSendChatMessage } from '../v2/ChatCompose'
import { useMainWindowContext } from '../shared/use-main-window'

export const CHAT_ITEM_HEIGHT = 46
const INSET_PADDING = 6

export const SideChatItem = memo(
  ({
    isActive,
    chat,
    isSelf,
    onClick,
    unreadCount,
    onDoubleClick,
    currentUserTimezone,
    isAdmin,
    topMessageId,
    openMenu,
  }: {
    isActive?: boolean
    chat: NewChatWithPeer
    isSelf: boolean
    unreadCount: number
    currentUserTimezone: string | null | undefined
    onClick?: () => void
    onDoubleClick?: () => void
    openMenu: (type: 'member' | 'group', id: string) => void
    isAdmin: boolean
    topMessageId: string | undefined | null
  }) => {
    let theme = useTheme()
    let [hovered, hoverListener] = useHover()
    let peerTopic = chat.peerTopic
    // let peerUser = chat.peerUser
    let [peerUser] = useNurNode<UserInfo>({ id: chat.peerUserId || null })
    let isOnline = peerUser?.online
    let activeChatPeer = useAtomValue(activeNewChatPeerAtom)
    let [droppedFile, setDroppedFile] = useState<File | undefined>(undefined)

    let { uploadFile } = useChatUploader()
    let sendChatMessageObject = useSendChatMessage()
    let draftMessage = useDraftMessage()

    let { aboutToDrop, registerDropAction } = useDrop({
      onDrop: (file) => {
        onClick?.()
        setActiveChatPeer({
          peerTopicId: chat.peerTopicId,
          peerUserId: chat.peerUserId,
        })
        dispatch({ type: 'change mode', mode: 'chat' })
        setDroppedFile(file)
      },
    })

    // we can not use photoUploader in onDrop directly, first render it does not works
    useEffect(() => {
      if (
        activeChatPeer.peerTopicId !== chat.peerTopicId &&
        activeChatPeer.peerUserId !== chat.peerUserId
      )
        return
      if (!droppedFile) return
      uploadFile({ file: droppedFile }).then((document) => {
        sendChatMessageObject.sendChatMessage({
          peerTopicId: chat.peerTopicId || null,
          peerUserId: chat.peerUserId || null,
          text: draftMessage?.message || '',
          documentId: document.id,
          document,
        })
      })
      setDroppedFile(undefined)
    }, [
      activeChatPeer.peerTopicId,
      activeChatPeer.peerUserId,
      chat.peerTopicId,
      chat.peerUserId,
      draftMessage?.message,
      droppedFile,
      sendChatMessageObject,
      uploadFile,
    ])

    // Prevent flash
    let topMessage = useMessageWithSender({ id: topMessageId || null })

    let backgroundColor = isActive
      ? `#895AC5`
      : aboutToDrop
      ? `rgba(255, 255, 255, 0.1)`
      : `rgba(92, 122, 197, 0)`

    let peer: ChatPeer = { topic: peerTopic || null, user: peerUser || null }
    let peerType: ChatPeerType = peerTopic ? 'Topic' : 'User'

    let userId = peerUser?.id
    // const { walkieState } = useRtcContext()
    // const participant = userId ? walkieState.participants?.[userId] : undefined
    // let idleState = participant?.idleState
    let idleState = ''
    const [, sendNudge] = useNudge({ recipientId: userId || '' })

    useRerenderEvery('1m')

    let showUnreadCount = !isSelf && unreadCount > 0
    let shortName = peerUser
      ? peerUser.nickname || getShortName(peerUser) || ''
      : ''
    let fullName = peerUser
      ? peerUser.name || peerUser.nickname || peerUser.email || ''
      : ''

    let { dispatch } = useMainWindowContext()
    let setActiveChatPeer = useUpdateAtom(activeNewChatPeerAtom)

    let haveNudge = !isSelf && peerUser

    return (
      <WebStyled
        onDoubleClick={onDoubleClick}
        onContextMenu={() => {
          if (peerTopic) {
            openMenu('group', peerTopic.id)
          }
        }}
        ref={(ref: HTMLDivElement) => {
          registerDropAction(ref)
        }}
        onClick={() => {
          onClick?.()
          setActiveChatPeer({
            peerTopicId: chat.peerTopicId,
            peerUserId: chat.peerUserId,
          })
          dispatch({ type: 'change mode', mode: 'chat' })
        }}
        {...hoverListener}
      >
        <TouchableOpacity
          activeOpacity={1}
          onPress={() => {
            onClick?.()
            setActiveChatPeer({
              peerTopicId: chat.peerTopicId,
              peerUserId: chat.peerUserId,
            })
            dispatch({ type: 'change mode', mode: 'chat' })
          }}
          {...hoverListener}
        >
          <View
            style={[
              {
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                marginLeft: sidebarHorizontalPadding - INSET_PADDING,
                marginRight: 6,
                paddingHorizontal: INSET_PADDING,
                borderRadius: 8,
                //@ts-ignore
                cursor: 'default',
              },
              { backgroundColor: backgroundColor },
            ]}
          >
            <View>
              <ChatAvatar
                peer={peer}
                size={34}
                isActive={isActive}
                isSelf={isSelf}
              />
              {haveNudge && (
                <AvatarNudge
                  show={hovered}
                  sendNudge={sendNudge}
                  isUs={isSelf}
                  size={34}
                />
              )}
              {peerUser && (
                <AvatarIndicator
                  right={1}
                  bottom={1}
                  type={
                    idleState && idleState === 'idle'
                      ? 'idle'
                      : isOnline
                      ? 'online'
                      : 'none'
                    // : 'offline'
                  }
                />
              )}
            </View>

            <View
              style={{
                marginLeft: 12,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'center',
                flex: 1,
                flexShrink: 1,
                // paddingRight: sidebarHorizontalPadding - 6,
                height: CHAT_ITEM_HEIGHT,
                // borderBottomWidth: 1,
                // borderBottomColor: isActive
                //   ? 'transparent'
                //   : theme.colors.separatorLine,
              }}
            >
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  flexShrink: 1,
                  minWidth: 0,
                  maxWidth: '100%',
                }}
              >
                <Text
                  numberOfLines={1}
                  ellipsizeMode={'tail'}
                  style={{
                    fontSize: theme.fontSizes.normal,
                    color: isActive
                      ? theme.colors.text
                      : theme.colors.primaryText,
                    letterSpacing: 0.01,
                    overflow: 'hidden',
                    lineHeight: 18,
                  }}
                >
                  {peerTopic?.title || fullName}
                </Text>
                {chat?.muted ? (
                  <MuteIcon
                    width={14}
                    height={14}
                    style={{ marginLeft: 5 }}
                    color={theme.colors.quaternaryText}
                  />
                ) : null}

                {/* Push to far edge */}
                {/* timezone */}
                {shouldShowLocalTime(
                  peerUser,
                  currentUserTimezone,
                  shortName,
                  hovered,
                ) ? (
                  <LocalTime timezone={peerUser.timezone} />
                ) : null}
              </View>

              {/* Show last message */}
              <View style={{ flexDirection: 'row', width: '100%' }}>
                <Text
                  style={{
                    fontSize: 12,
                    color: isActive
                      ? theme.colors.tertiaryText
                      : theme.colors.quaternaryText,
                    lineHeight: 14,
                    textAlign: 'left',
                    flexGrow: 1,
                  }}
                  numberOfLines={1}
                >
                  {topMessage && topMessage.sender
                    ? getTopMessageSubtitle(
                        peerType,
                        topMessage,
                        topMessage.sender,
                      )?.replace(/(\r\n|\n|\r)/gm, ' ')
                    : ''}
                </Text>
                <UnreadCount
                  show={showUnreadCount}
                  count={unreadCount}
                  active={!!isActive}
                />
              </View>
            </View>
          </View>
        </TouchableOpacity>
      </WebStyled>
    )
  },
  deepEqual,
)

// TODO: Add image support
function getTopMessageSubtitle(
  peerType: ChatPeerType,
  topMessage: ChatMessageInfo,
  topMessageSender: UserInfo,
) {
  switch (peerType) {
    case 'User':
      return getMessageOneLiner(topMessage)

    case 'Topic':
      return `${getShortName(topMessageSender)}: ${getMessageOneLiner(
        topMessage,
      )}`
  }
}

function shouldShowLocalTime(
  user: UserInfo | null | undefined,
  currentUserTimezone: string | null | undefined,
  shortName: string,
  hovered: boolean,
): user is UserInfo & { timezone: string } {
  // We know timezone has a value now
  return !!(
    user &&
    user.timezone &&
    // If shortname is long, show on hover
    (shortName.length < 7 || hovered) &&
    // Only show if current user timezone is different
    user.timezone !== currentUserTimezone
  )
}

const LocalTime = ({ timezone }: { timezone: string }) => {
  const theme = useTheme()

  return (
    <Tooltip label={`${timezone.split('/')[1].replace('_', ' ')} Time`}>
      <View style={{ height: 12 }}>
        <Text
          style={{
            fontVariant: ['tabular-nums'],
            color: theme.colors.quaternaryText,
            fontSize: theme.fontSizes.small,
            flexShrink: 0,
            lineHeight: 12,
            height: 15,
            marginLeft: 10,
            marginTop: 1,
          }}
          numberOfLines={1}
        >
          <TimezoneTime timeZone={timezone} />
        </Text>
      </View>
    </Tooltip>
  )
}

const useMessageWithSender = (input: { id: string | null }) => {
  let topMessageId = input.id

  let [topMessage, setTopMessage] = useState<
    (ChatMessageInfo & { sender: UserInfo | null }) | null
  >(null)
  let [topMessageCache, { getNode }] = useNurNode<ChatMessageInfo>({
    id: topMessageId || null,
  })
  let [topMessageSenderCache] = useNurNode<UserInfo>({
    id: topMessage?.senderId || null,
  })

  let [topMessageDocumentCache] = useNurNode<DocumentInfo>({
    id: topMessage?.documentId || null,
  })
  useEffect(() => {
    if (!topMessageId) return
    // Have to use two getNodes to get all information in one go, rather than 2 set states using native useNur node
    let topMessage = topMessageCache || getNode({ id: topMessageId })
    if (!topMessage) return
    setTopMessage({
      ...topMessage,

      // First time cache is null, second time use the subscribed
      sender:
        topMessageSenderCache ||
        (getNode({
          id: topMessage?.senderId || null,
        }) as UserInfo | null),
      document: (topMessageDocumentCache as DocumentInfo) || null,
    })
  }, [
    getNode,
    topMessageId,
    topMessage?.senderId,
    topMessageSenderCache,
    topMessageCache,
    topMessageDocumentCache,
  ])
  return topMessage
}
