import { useLeaveDialog } from '@there/components/main/useLeaveDialog'
import { AppState } from '@there/components/shared/AppContext'
import { SpaceContextManager } from '@there/components/shared/spaceContext'
import { logEvent } from '@there/components/shared/use-analytics'
import { useLatest } from '@there/components/shared/use-latest'
import { RtcManager } from '@there/components/shared/use-rtc'
import { useJoinDialog } from '@there/components/shared/useJoinDialog'
import {
  electronSupports,
  ipc,
  useListenToIpc,
} from '@there/desktop/utils/electron-api'
import { atom, useAtom } from 'jotai'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import { ShortcutActions } from '../shared/use-shortcut-manager'
import { usePreviousDistinct } from 'react-use'
import { useSoundEffect } from '@there/components/feed/useSoundEffect'
import { usePowerMonitor } from './BackgroundHooks/use-power-monitor'
import Sentry from '@there/app/utils/sentry'
import { nanoid } from 'nanoid/non-secure'
import { useProSpace } from '../pro/useProSpace'
import { RtcAvatars } from '@there/components/shared/use-rtc-avatars'
import ms from 'ms'
import { useAlert } from '../modal/use-alert'
import { EmojiView } from '../main/EmojiView'
import { useModals } from './ModalsContext'
import { contentViewAtom } from '../atoms/contentViewAtom'
import { usePersistedState } from '../hooks/usePersistState'
import {
  FeedWindowManager,
  useMainWindowContext,
} from '../shared/use-main-window'
let debug = require('debug')('app:use-lobby-man')

type Input = {
  spaceManager: SpaceContextManager
  appState: AppState
  rtcManager: RtcManager
  rtcAvatars: RtcAvatars
  feedWindowManager: FeedWindowManager
}
export type CallDetails = {
  hasActiveCall?: boolean
  hasScreenShare?: boolean
  hasCamera?: boolean
  maximumParticipants: number
}

const intervalTimeout = ms('1min')
const screenShareLimitTime = ms('60min')

/**
 * Maintain optimistic rtc call fix, provide proxy atoms at the intersection
 * of rtcManager and spaceManager to reduce updates count
 */
export const useLobbyMan = ({
  spaceManager,
  appState,
  rtcManager,
  rtcAvatars,
  feedWindowManager,
}: Input) => {
  let [
    {
      dialogs,
      getAvatar,
      getDialog,
      lobbyDialog,
      fetching: spaceFetching,
      getDialogAvatars,
    },
  ] = spaceManager
  let { avatarId, activeSpaceId, currentUserId, autoEnableMic } = appState
  let {
    triggerParticipantsReset,
    walkieState,
    toggleTalk,
    isInitiallyConnecting,
    inActiveCall,
  } = rtcManager

  let autoEnableMicRef = useLatest(autoEnableMic)

  let isTalking = walkieState.weTalking

  let [{ stale, data, fetching }, joinDialogMutation] = useJoinDialog()
  let [, leaveDialogMutation] = useLeaveDialog()

  let hasData = Boolean(data?.updates)

  let previousFetching = usePreviousDistinct(fetching)
  /**
   * Because call starts while optimistic state is applied,
   * the offers we send fail.
   * So after optimistic we reset the call peers.
   */
  useEffect(() => {
    if (
      hasData &&
      fetching === false &&
      // To prevent re-resetting on page change
      previousFetching === true &&
      stale === false
    ) {
      console.info('Reset call after network response')
      // I disabled queuing offers, so this must be back
      // @note(mo): Not necccessary any more because we handle new signals ourselves
      // triggerParticipantsReset({ onlyThoseWeAreInitialOfferer: true })
      // triggerParticipantsReset({ onlyThoseWeAreImpolite: true })
      // if we're not initial offerer do not reset

      // Brought back because now we only send on initialOfferer and per Mo testing it works better
      triggerParticipantsReset({ onlyThoseWeAreInitialOfferer: true })
    }
  }, [
    hasData,
    previousFetching,
    fetching,
    triggerParticipantsReset,
    stale,
    data,
  ])

  let [playConnectedAudio] = useSoundEffect('/tones/single-plink.mp3', {
    volumeMultiplier: process.env.NODE_ENV === 'development' ? 0.01 : 0.5,
  })
  let previousInitiallyConnecting = usePreviousDistinct(isInitiallyConnecting)
  let callId = walkieState.callId
  let firstConnected = useLatest(walkieState.firstParticipantConnected)
  let shouldPlay = useRef(false)

  // 🔉
  // Play sound on initial connect
  useEffect(() => {
    if (!callId) {
      shouldPlay.current = false
    }

    if (
      isInitiallyConnecting === false &&
      // Used to prevent playing on initial app laod
      previousInitiallyConnecting === true &&
      shouldPlay.current &&
      firstConnected.current
    ) {
      playConnectedAudio()
    }

    // Pre-Condition
    if (callId && isInitiallyConnecting === true) {
      shouldPlay.current = true
    } else {
      shouldPlay.current = false
    }
  }, [
    isInitiallyConnecting,
    playConnectedAudio,
    previousInitiallyConnecting,
    callId,
    firstConnected,
  ])

  // let avatar = avatarId ? getAvatar(avatarId) : undefined
  // let currentDialog = avatar?.dialogId ? getDialog(avatar.dialogId) : undefined
  let currentDialog = rtcAvatars.dialog
  let avatarsRef = useLatest(rtcAvatars.avatars)
  // let isActive = !!currentDialog || currentDialog?.specialType === 'lobby'
  let getDialogAvatarsFunction = useLatest(getDialogAvatars)
  let getDialogFunction = useLatest(getDialog)
  let isActive = !!currentDialog
  let dialogsRef = useLatest(dialogs)
  let currentDialogId = currentDialog?.id
  let currentDialogSpaceId = currentDialog?.spaceId
  let [contentView, setContentView] = useAtom(contentViewAtom)

  let [playClickIn] = useSoundEffect('/tones/click-in.mp3')
  let [playClickOut] = useSoundEffect('/tones/click-out.mp3', {
    volumeMultiplier: 0.7,
  })
  let [playJoinRoomSound] = useSoundEffect('/tones/join-room.mp3')
  let { isProSpace } = useProSpace(activeSpaceId)
  const feedWindowManagerDispatch = feedWindowManager.dispatch
  const leaveDialog = useCallback(
    (spaceId?: string) => {
      // leave
      let dialogId = currentDialogId
      if (!avatarId) return
      if (!activeSpaceId) return
      if (!currentUserId) return

      leaveDialogMutation({
        spaceId: spaceId || activeSpaceId,
        __extra: {
          avatarId,
          dialogId,
          spaceId: activeSpaceId,
          userId: currentUserId,
        },
      })

      playClickOut()

      logEvent('User Left a Room', { from: 'Hibernate' })
    },
    [
      currentDialogId,
      avatarId,
      activeSpaceId,
      currentUserId,
      leaveDialogMutation,
      playClickOut,
    ],
  )

  useEffect(() => {
    // if in the gameRoom redirect to rooms
    const isGameRoom = currentDialog?.title?.toLowerCase().includes('game')

    if (feedWindowManager.mode == 'room' && !isGameRoom) {
      feedWindowManagerDispatch({
        type: 'change mode',
        mode: 'room',
        isGameRoom: false,
      })
    }
  }, [currentDialog, feedWindowManager.mode, feedWindowManagerDispatch])

  let sessionsJoinedAtRef = useRef<Record<string, Date>>({})
  let dialogJoinedAtRef = useRef<Record<string, Date>>({})
  let dialogLastSessionIdsRef = useRef<Record<string, string>>({})
  const joinDialog = useCallback(
    (dialogId: string) => {
      let oldDialogId = currentDialogId

      if (!avatarId) {
        console.error('avatarId not found')
        return
      }

      if (!activeSpaceId) {
        console.error('activeSpaceId not found')
        return
      }

      // Check how many people are inside
      const MAX_IN_ROOM = 6
      let avatarsCount = getDialogAvatarsFunction.current?.(dialogId)?.length
      // let avatarsCount = avatarsRef.current?.length
      if (avatarsCount && avatarsCount >= MAX_IN_ROOM && !isProSpace) {
        // TODO: Replace with modal
        alert(
          `Rooms are limited to ${MAX_IN_ROOM} people, but you can join another room. Super rooms available on Pro plans.`,
        )
        return
      }

      // If we're joining the room for the first time, start a new session
      // This is also checked on server-side
      const newSessionId = avatarsCount === 0 ? nanoid(18) : undefined

      if (currentDialogSpaceId && currentDialogSpaceId !== activeSpaceId) {
        leaveDialog(currentDialogSpaceId)
      }

      joinDialogMutation({
        dialogId: dialogId,
        joinedAt: Date.now(),
        newSessionId,
        __extra: {
          avatarId: avatarId,
          spaceId: activeSpaceId,
          oldDialogId,
        },
      })

      logEvent('User Joined a Room')

      playJoinRoomSound()
      dialogJoinedAtRef.current[dialogId] = new Date()
      // if (newSessionId) {
      //   dialogLastSessionIdsRef.current[dialogId] = newSessionId
      //   sessionsJoinedAtRef.current[newSessionId] = new Date()
      // }

      setTimeout(() => {
        let dialog = getDialogFunction.current?.(dialogId)
        if (!isTalking && autoEnableMicRef.current && !dialog?.silent) {
          debug('Auto-toggle talk')
          toggleTalk()
        }
      }, 140)
    },
    [
      currentDialogId,
      avatarId,
      activeSpaceId,
      getDialogAvatarsFunction,
      getDialogFunction,
      isProSpace,
      currentDialogSpaceId,
      joinDialogMutation,
      playJoinRoomSound,
      leaveDialog,
      isTalking,
      autoEnableMicRef,
      toggleTalk,
    ],
  )

  const joinLobby = useCallback(() => {
    let lobbyDialog = dialogsRef.current?.find(
      (dialog) => dialog.specialType === 'lobby',
    )

    if (!lobbyDialog) {
      alert('No lobby room found. Please contact support to fix it quickly.')
      return
    }

    // Disabled click in for now
    // playClickIn()
    joinDialog(lobbyDialog.id)
  }, [dialogsRef, joinDialog])

  let joinDialogRef = useLatest(joinDialog)
  let joinLobbyRef = useLatest(joinLobby)
  let leaveDialogRef = useLatest(leaveDialog)

  // Pass down data as atoms
  let [, setJoinDialog] = useAtom(joinDialogAtom)
  let [, setJoinLobby] = useAtom(joinLobbyAtom)
  let [, setLeaveDialog] = useAtom(leaveDialogAtom)
  let [, setIsActive] = useAtom(isActiveAtom)
  let [, setCurrentDialogId] = useAtom(currentDialogIdAtom)
  let [, setLobbyDialogId] = useAtom(lobbyDialogIdAtom)

  useEffect(() => {
    setJoinDialog({
      joinDialog: (dialogId: string) => {
        return joinDialogRef.current?.(dialogId)
      },
    })
  }, [joinDialogRef, setJoinDialog])

  useEffect(() => {
    setJoinLobby({
      joinLobby: () => {
        return joinLobbyRef.current?.()
      },
    })
  }, [joinLobbyRef, setJoinLobby])

  useEffect(() => {
    setLeaveDialog({
      leaveDialog: () => {
        return leaveDialogRef.current?.()
      },
    })
  }, [leaveDialogRef, setLeaveDialog])

  useEffect(() => {
    setIsActive(isActive)
  }, [isActive, setIsActive])

  useEffect(() => {
    setCurrentDialogId(currentDialogId || null)
  }, [currentDialogId, setCurrentDialogId])

  useEffect(() => {
    setLobbyDialogId(lobbyDialog?.id || null)
  }, [lobbyDialog, setLobbyDialogId])

  // leaveDialog if user changed space but opened in another space's dialog
  // useEffect(() => {
  //   // we can't use currentDialog because it dependent on active space
  //   let currentDialogId = avatar?.dialogId
  //   if (!currentDialogId) return
  //   if (!getDialog(currentDialogId) && !spaceFetching) {
  //     console.info('Automatically left dialog, as space changed.')
  //     leaveDialog()
  //   }
  // }, [avatar, getDialog, leaveDialog, spaceFetching])

  useListenToIpc(
    'shortcut-pressed',
    useCallback(
      (_, data: { action: ShortcutActions; shortcutString: string }) => {
        switch (data?.action) {
          case 'toggleVoice':
            if (!isActive) {
              joinLobby()
            } else {
              leaveDialog()
            }
            logEvent('User Toggled Voice - Shortcut')
            break

          default:
            break
        }
      },
      [isActive, joinLobby, leaveDialog],
    ),
  )

  useListenToIpc(
    'dialog:leave',
    useCallback(() => {
      leaveDialog()
    }, [leaveDialog]),
  )

  // Leave dialog on sleep or lock
  const onPowerChange = useCallback(
    (event: 'suspend' | 'resume' | 'lock-screen' | 'unlock-screen') => {
      switch (event) {
        case 'lock-screen':
          if (!currentDialogId) return
          leaveDialog()
          break
      }
    },
    [leaveDialog, currentDialogId],
  )

  usePowerMonitor({
    onChange: onPowerChange,
  })

  // Prevent to sleep when isActive
  useEffect(() => {
    ipc?.invoke('system:sleep-blocker', inActiveCall).catch((error) => {
      if (!electronSupports?.sleepBlocker) return
      Sentry.captureException(error)
    })
  }, [inActiveCall])

  /**
   * Rate Call
   */
  let rateSessionIdRef = useRef<string | null | undefined>(null)
  let [, modalsDispatch] = useModals()
  // let sessionId = currentDialog?.sessionId
  let dialogId = currentDialog?.id
  let participantsNum = rtcAvatars.avatars.length
  let rateDialogIdRef = useRef<string | null | undefined>(null)

  let callDetailsRef = useRef<Record<string, CallDetails>>({})

  let inScreenShare = walkieState.weSharingScreen || walkieState.isObserving
  let sharingCamera = walkieState.weSharingCamera
  let [previousRateTime, setNewRateTime] = usePersistedState<number>(
    'previousRateTime',
    0,
  )
  let previousRateTimeRef = useLatest(previousRateTime)
  useEffect(() => {
    if (!rateDialogIdRef.current) return
    if (!inActiveCall) return

    let callDetails = callDetailsRef.current[rateDialogIdRef.current]
    let hasScreenShare = inScreenShare ? { hasScreenShare: true } : {}
    let hasCamera = sharingCamera ? { hasCamera: true } : {}

    let maximumParticipants = {
      maximumParticipants: callDetails
        ? Math.max(callDetails.maximumParticipants, participantsNum)
        : participantsNum,
    }

    callDetailsRef.current[rateDialogIdRef.current] = {
      hasActiveCall: true,
      ...callDetails,
      ...hasCamera,
      ...hasScreenShare,
      ...maximumParticipants,
    }
  }, [inActiveCall, inScreenShare, participantsNum, sharingCamera])

  useEffect(() => {
    let now = new Date()
    let joinedAt = rateDialogIdRef.current
      ? dialogJoinedAtRef.current[rateDialogIdRef.current]
      : undefined

    // on join a call
    if (!rateDialogIdRef.current) {
      rateDialogIdRef.current = dialogId
      return
    }

    let callDetails = callDetailsRef.current[rateDialogIdRef.current]
    let shouldShowModal = joinedAt
      ? shouldShowRateCallModal({
          joinedAt,
          callDetails,
          previousRateTime: previousRateTimeRef.current,
        })
      : false

    // on left a call
    if (!dialogId && rateDialogIdRef.current) {
      if (shouldShowModal) {
        setNewRateTime(now.getTime())
        modalsDispatch({
          type: 'modal opened',
          modalName: 'userRate',
          modalData: { dialogId: rateDialogIdRef.current, callDetails },
        })
      }
      rateDialogIdRef.current = dialogId
      return
    }

    // on change dialog immediately (if session id doest not go undefined)
    if (dialogId !== rateDialogIdRef.current) {
      if (shouldShowModal) {
        setNewRateTime(now.getTime())
        modalsDispatch({
          type: 'modal opened',
          modalName: 'userRate',
          modalData: { dialogId: rateDialogIdRef.current, callDetails },
        })
      }
      rateDialogIdRef.current = dialogId
      return
    }

    rateDialogIdRef.current = dialogId
  }, [
    currentDialog,
    modalsDispatch,
    dialogId,
    setNewRateTime,
    previousRateTimeRef,
  ])

  /**
   * Limits
   */

  // screen share limit
  // 60min host
  // let sessionIdRef = useRef<string | null | undefined>(null)
  // let weSharingScreen = rtcManager.walkieState.weSharingScreen
  // let sharedTimeRef = useRef(0)

  // let toggleScreenSharingRef = useLatest(rtcManager.toggleScreenSharing)

  // let { openModal: openAlertModal } = useAlert({
  //   alertIcon: <EmojiView size={38}>😯</EmojiView>,
  //   alertText: 'That was 60 minutes',
  //   alertDescription: 'Free spaces can have up to\n60 minutes of screen share.',
  //   submitLabel: 'Ok',
  // })
  // let openAlertModalRef = useLatest(openAlertModal)
  // useEffect(() => {
  //   if(isProSpace) return
  //   // reset timer on sessionId changes
  //   if (!sessionId) return
  //   if (sessionId !== sessionIdRef.current) {
  //     sharedTimeRef.current = 0
  //     sessionIdRef.current = sessionId
  //   }

  //   if (!weSharingScreen) return

  //   let interval = setInterval(() => {
  //     sharedTimeRef.current = sharedTimeRef.current + intervalTimeout
  //     if (sharedTimeRef.current >= screenShareLimitTime && weSharingScreen) {
  //       toggleScreenSharingRef.current()
  //       ipc?.invoke('feed:focus')
  //       openAlertModalRef.current()
  //     }
  //   }, intervalTimeout)

  //   return () => clearInterval(interval)
  // }, [isProSpace, openAlertModalRef, sessionId, toggleScreenSharingRef, weSharingScreen])

  return { joinDialog }
}

export const isActiveAtom = atom(false)
export const currentDialogIdAtom = atom<string | null>(null)
export const lobbyDialogIdAtom = atom<string | null>(null)

export const joinDialogAtom = atom({
  joinDialog: (dialogId: string) => {
    console.warn('Join dialog atom called too fast')
  },
})

export const joinLobbyAtom = atom({
  joinLobby: () => {
    console.warn('Join lobby called too fast')
  },
})

export const leaveDialogAtom = atom({
  leaveDialog: () => {
    return console.warn('Leave lobby called too fast')
  },
})

const showRateCallAfterMs = ms('10s')
const shouldShowRateCallModal = ({
  joinedAt,
  callDetails,
  previousRateTime,
}: {
  joinedAt: Date
  callDetails: CallDetails
  previousRateTime: number
}) => {
  let now = new Date()
  if (!callDetails) return false
  if (!callDetails.hasActiveCall) false
  if (
    now.getTime() - previousRateTime > ms('3h') &&
    now.getTime() - joinedAt.getTime() > showRateCallAfterMs &&
    callDetails.hasActiveCall &&
    callDetails.maximumParticipants > 1
  ) {
    return true
  } else {
    return false
  }
}
