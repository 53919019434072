import React, { ReactNode, useEffect, useRef, useState } from 'react'
import {
  ConnectionState,
  networkStateToConnectionState,
} from '@there/components/sun/network'
import { useUpdateAtom } from 'jotai/utils'
import { connectionStateAtom } from '@there/components/atoms'
import { createNurClient, NurContext } from '@there/components/sun/context'
import { Client } from '@there/components/sun/client'
import { FeedSkeleton } from '@there/components/feed/FeedSkeleton'

export function NurProvider({ children }: { children: ReactNode }) {
  let [nurClient, setNurClient] = useState<Client>()
  let setConnectionState = useUpdateAtom(connectionStateAtom)

  useEffect(() => {
    createNurClient().then((client) => {
      setNurClient(client)
    })
  }, [])

  React.useEffect(() => {
    if (!nurClient) return

    const stateChange = (state: ConnectionState) => {
      if (!state) return
      setConnectionState(networkStateToConnectionState(state))
    }
    let network = nurClient.network

    network.addListener('stateChange', stateChange)
    return () => {
      network.removeListener('stateChange', stateChange)
    }
  }, [nurClient, setConnectionState])

  if (!nurClient) {
    return <FeedSkeleton />
  }

  return <NurContext.Provider value={nurClient}>{children}</NurContext.Provider>
}
