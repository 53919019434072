import { useEffect } from 'react'
import { ipc } from '@there/desktop/utils/electron-api'

type EventTypes = 'suspend' | 'resume' | 'unlock-screen' | 'lock-screen'

export const usePowerMonitor = (input: {
  onChange: (event: EventTypes) => void
}): void => {
  let onChange = input.onChange

  useEffect(() => {
    if (!ipc) return

    function powerMonitorChanged(_: any, changeType: EventTypes) {
      switch (changeType) {
        case 'suspend':
          // Error prone!!!
          onChange?.('suspend')
          break

        case 'resume':
          onChange?.('resume')
          break

        case 'unlock-screen':
          onChange?.('unlock-screen')
          break

        case 'lock-screen':
          onChange?.('lock-screen')
          break
      }
    }

    ipc?.addListener('system:power-monitor-changed', powerMonitorChanged)

    return () => {
      ipc?.removeListener('system:power-monitor-changed', powerMonitorChanged)
    }
  }, [onChange])
}
